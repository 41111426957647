.app {
  /*background: rgb(0, 0, 0);*/
  /* background: radial-gradient(circle, rgba(224,224,224,1) 0%, rgba(32,32,32,1) 100%); */
  text-align: center;
  padding-bottom:0px;
  /*height:100%;*/
  /*height: 100vh;*/
  width: 100%;
  background: black;
  background-color: black;
}

body {
  width: 100%;
  text-align:center;
  background: black;
  background-color: black;
  /* background: rgb(134, 134, 134); */
  /* background: radial-gradient(circle, rgba(32,32,32,1) 0%, rgba(224,224,224,1) 100%); */
  /* background: radial-gradient(circle, rgba(224,224,224,1) 0%, rgba(32,32,32,1) 100%); */
}

.main-content {
  width: 100%;
  margin: 2rem 0% 0 0%;
  height: 100%;
  /*height: 20vh;*/
  /*background-color: rgba(255, 255, 255, 1.0);*/
  background-color: black;
  color : rgb(0, 0, 0);
}

.logo-div div {
  height: 100%;
  pointer-events: none;
  background-color: black;
}

/*.App-logo {
  background: black;
}*/

.logo img {
  background-color: black;
  background: black;
  width: 360px;
  height: 50px;
}

.copyrightgb {
  background: rgba(0, 0, 0, 1.0);
  background-color: black;
  height: 6vh;
  /*margin: 2rem 0% 0 0%;*/
  font-size: calc(5px + 2vmin);
  color: aliceblue;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*background: rgb(255, 255, 255);*/
    margin-left:5px;
    margin-top:5px;
    height: 50px;
    width: 360px;
    /* animation: App-logo-spin 3s 5s linear; */
    /* transition: opacity 500ms ease-in; */
    animation-duration: 3s;
    animation-name: App-logo-grow;
    animation-iteration-count: 1;
    /* animation-direction: alternate; */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background_gradient_two {
  --color-1: black;
  --color-2: grey;

  background: linear-gradient(170deg, var(--color-1) 20%, var(--color-2));
}

.background_gradient_three {
  --color-1: black;
  --color-2: grey;

  background: linear-gradient(170deg, var(--color-1) 20%, var(--color-2), var(--color-1));
}

.background_gradient_logo {
  --color-1: rgb(15, 15, 15);
  --color-2: grey;

  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1), var(--color-2));
}

.background_gradient_logo_inverse {
  --color-1: black;
  --color-2: grey;

  background: linear-gradient(170deg, var(--color-1) 20%, var(--color-2), var(--color-1));
}

.background_gradient_two_inverse {
  --color-1: black;
  --color-2: grey;

  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1));
}

.background_gradient_three_inverse {
  --color-1: black;
  --color-2: grey;

  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1), var(--color-2));
}

.background_gradient_three_inverse_light {
  --color-1: rgb(219, 219, 219);
  --color-2: rgb(122, 122, 122);

  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1), var(--color-2));
}

.background_gradient_three_inverse_light_logo {
  --color-1: rgb(219, 219, 219);
  --color-2: rgb(122, 122, 122);

  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1), var(--color-2));
}

@keyframes App-logo-grow {
  0% {
    transform: translateX(-200px) scaleX(0.5);
  }
  20% {
    transform: translateX(-50px) scaleX(0.1);
  }
}

@keyframes App-logo-move {
  40% {
    transform: translateX(-500px);
  }
  80% {
    transform: translateX(500px);
  }
}

@keyframes App-logo-spin {
  20% { 
    
    transform: skewX(50deg) skewY(10deg); 
    /* background-blend-mode: lighten; */
  }
  40% { 
    
    background-color: rgba(206, 173, 28, 0.2);
  }
  60% { 
    transform: skewX(-50deg) skewY(-10deg);
  }
  80% { 
    background-color: rgba(247, 0, 255, 0.1);
    transform-origin: -500px;
  }
}
