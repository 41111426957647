//content.scss
.content {
  font-size: calc(5px + 2vmin);
  width: 80%;
  margin: 2rem 10% 0 10%;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 25vh;
  //background: rgb(0, 0, 0);
  //height:40%;
  padding-bottom:0px;
}

.background_gradient_two {
  // some color reactjs variables
  --color-1: black;
  --color-2: rgb(192, 192, 192);

  //Use the color variables to set the background gradient
  background: linear-gradient(170deg, var(--color-1) 20%, var(--color-2));
}

.background_gradient_three {
  // some color reactjs variables
  --color-1: black;
  --color-2: rgb(192, 192, 192);

  //Use the color variables to set the background gradient
  background: linear-gradient(170deg, var(--color-1) 20%, var(--color-2), var(--color-1));
}

.background_gradient_two_inverse {
  // some color reactjs variables
  --color-1: black;
  --color-2: grey;

  //Use the color variables to set the background gradient
  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1));
}

.background_gradient_three_inverse {
  // some color reactjs variables
  --color-1: black;
  --color-2: grey;

  //Use the color variables to set the background gradient
  background: linear-gradient(170deg, var(--color-2) 20%, var(--color-1), var(--color-2));
}

.main-content {
    width: 70%;
    margin: 2rem 0% 0 0%;
    height: 50vh;
    //height: 100%;
    //background-color: rgba(187, 0, 255, 1.0);
    //color : white;
}

.title-1 {
  background-color: rgb(255, 115, 0);
}

.link {
  color: rgb(0, 217, 255);
}

.link:hover {
   color: rgb(0, 165, 194);
}

.box {
  width: 100%;
  //min-height: 100%;
  //height: 15vmin;
  min-height: 15vmin;
}

.top-content {
  width: 40%;
  background-color: rgba(255, 238, 0, 1.0);
}

.content-header-top {
  height: 5vh;
  min-height: 5vmin;
  pointer-events: none;
  letter-spacing: 3px;
}

.middle-content {
  width: 50%;
  background-color: rgba(21, 255, 0, 1.0);
}

.content-header-middle {
  height: 5vmin;
  min-height: 5vmin;
  pointer-events: none;
  letter-spacing: 3px;
  margin-top: 0px;
}

.bottom-content {
  width: 60%;
  background-color: rgba(255, 0, 242, 1.0);
}

.content-header-bottom {
  height: 5vmin;
  min-height: 5vmin;
  pointer-events: none;
  letter-spacing: 3px;
  margin-top: 0px;
}



@media (prefers-reduced-motion: no-preference) {
  .content-header-top {
    /* animation: App-logo-spin 3s 5s linear; */
    /* transition: opacity 500ms ease-in; */
    //animation-delay: 1s;
    animation-duration: 4.5s;
    animation-name: effect-grow;
    animation-iteration-count: 1;
    /* animation-direction: alternate; */
  }
}



@media (prefers-reduced-motion: no-preference) {
  .content-header-middle {
    /* animation: App-logo-spin 3s 5s linear; */
    /* transition: opacity 500ms ease-in; */
    //animation-delay: 2s;
    animation-duration: 5s;
    animation-name: effect-grow;
    animation-iteration-count: 1;
    /* animation-direction: alternate; */
  }
}



@media (prefers-reduced-motion: no-preference) {
  .content-header-bottom {
    /* animation: App-logo-spin 3s 5s linear; */
    /* transition: opacity 500ms ease-in; */
    animation-duration: 5.5s;
    animation-name: effect-grow;
    animation-iteration-count: 1;
    /* animation-direction: alternate; */
  }
}

@keyframes effect-grow {
  0% {
    transform: translateX(50px) scaleY(0.5);
  }
  20% {
    transform: translateX(50px) scaleY(1.0);
  }
}
